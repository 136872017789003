import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Button",
  "type": "Form"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Button`}</strong>{` component represents a clickable element on the screen, that is associated with
an action taken by the user. `}<strong parentName="p">{`Button`}</strong>{` is pretty common UX/UI action, so it comes with
different variations and styles.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <p>{`The regular `}<strong parentName="p">{`Button`}</strong>{` comes by default with `}<inlineCode parentName="p">{`primary`}</inlineCode>{` variation and supports `}<inlineCode parentName="p">{`hover`}</inlineCode>{`,
`}<inlineCode parentName="p">{`active`}</inlineCode>{` and `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` HTML states.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true",
        "live": "true",
        "editor": "false",
        "center": "true"
      }}>{`<Button variant="primary">Button</Button>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ButtonVariant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ButtonVariant.Primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The title of the navigation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon rendered on the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`loading`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Render the button as loading`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stretch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Render the button stretched in the container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ElementType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"button"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The underlying root node`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Variations for progressing actions`}</h2>
    <p>{`The standard set of buttons can be used whenever an action is part of a process or opens
up a variety of options to the user. Contrasting levels of emphasis help the user to
differentiate between important and less important actions. These levels can also be used
to guide a user to a preferred outcome.`}</p>
    <h3>{`Primary`}</h3>
    <p>{`Primary variant is for actions with a clear intention`}</p>
    <blockquote>
      <p parentName="blockquote">{`Default button without specified `}<strong parentName="p">{`variant`}</strong>{` is a primary button`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button>Button</Button>
`}</code></pre>
    <h3>{`Secondary`}</h3>
    <p>{`Secondary variant is for other possible actions`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button variant="secondary">Button</Button>
`}</code></pre>
    <h3>{`Tertiary`}</h3>
    <p>{`Tertiary variant is for less important actions than the secondary variant.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button variant="tertiary">Button</Button>
`}</code></pre>
    <h3>{`Alternative`}</h3>
    <p>{`Alternative variant can be used for progressing actions where none of the above variants
would be sufficient.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button variant="alternative">Button</Button>
`}</code></pre>
    <h3>{`Ghost`}</h3>
    <p>{`Ghost variant can be used for additional progressing action as an alternative of the
secondary variant, where a big emphasis on the element is unnecessary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button variant="ghost">Button</Button>
`}</code></pre>
    <h2>{`Variations for completing actions`}</h2>
    <p>{`This set of buttons can be used to complete or terminate an action. The labels should
give a clear indication of what the user can expect after clicking.`}</p>
    <h2>{`Confirmative`}</h2>
    <p>{`Confirmative button variant is for actions that are completing a certain flow of the
user journey. Examples for using the confirmative button are :`}</p>
    <ul>
      <li parentName="ul">{`Creating an entity`}</li>
      <li parentName="ul">{`Signing in the user`}</li>
      <li parentName="ul">{`Confirmation of user intent`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button variant="confirmative">Button</Button>
`}</code></pre>
    <h2>{`Destructive`}</h2>
    <p>{`Destructive button variant is for actions that are terminating a certain flow of the
user journey. Examples for using the destructive button are :`}</p>
    <ul>
      <li parentName="ul">{`Deleting an entity`}</li>
      <li parentName="ul">{`Cancelling an already progressing action`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button variant="destructive">Button</Button>
`}</code></pre>
    <h2>{`Warning`}</h2>
    <p>{`A warning button is for actions that are going to complete a certain flow
of the user journey, however the action is most probably undoable. Examples of using the
warning button are :`}</p>
    <ul>
      <li parentName="ul">{`Issuing an invoice to a client`}</li>
      <li parentName="ul">{`Sending an email invitation for registration`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button variant="warning">Button</Button>
`}</code></pre>
    <h2>{`States`}</h2>
    <p>{`Buttons follow the HTML states of `}<inlineCode parentName="p">{`active`}</inlineCode>{`, `}<inlineCode parentName="p">{`hover`}</inlineCode>{` and `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`. There are more
additional states available, like `}<inlineCode parentName="p">{`loading`}</inlineCode>{`.`}</p>
    <h3>{`Disabled`}</h3>
    <p>{`Disabled state is a state where the button is un-actionable and user action on it doesn't
produce a result.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Disabled buttons are all the same for every variant.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<Button disabled>Button</Button>
`}</code></pre>
    <h3>{`Loading`}</h3>
    <p>{`Loading state is when the button has been activated, however the result of the action
depends on another time-consuming process unrelated to the user.`}</p>
    <p>{`Typical use cases for the loading state are :`}</p>
    <ul>
      <li parentName="ul">{`Server-side validation`}</li>
      <li parentName="ul">{`Client-side calculation`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`The loading state makes the button un-actionable, however instead of being "disabled"
it contains a loader indicator.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`() => {
  const [loading, setLoading] = React.useState( false );
  const handleClick = () => {
    setLoading( true );
    setTimeout( ()=> setLoading( false ), 2500 )
  }
  return ( <Button onClick={handleClick} loading={loading}>Button</Button> );
}
`}</code></pre>
    <h2>{`Layout`}</h2>
    <p><strong parentName="p">{`Button`}</strong>{` is using the default display properties of a native HTML Button
(`}<inlineCode parentName="p">{`inline-block`}</inlineCode>{`). There are additional layout props that can be used to override
it.`}</p>
    <h3>{`Stretch`}</h3>
    <p>{`Will render the button stretched from one end to the other`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, textAlign: "center" }}>
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Button>Button</Button>
  </div>
  <Divider />
  <div>
    <Button stretch>Button</Button>
  </div>
</div>
`}</code></pre>
    <h2>{`Icons`}</h2>
    <p><strong parentName="p">{`Button`}</strong>{` can also render with a user specified left icon.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div>
  <Button icon={ <Icons.BiMailSend /> }>Button</Button>
  <Separator />
  <Button icon={ <Icons.BiMailSend /> } disabled>Button</Button>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      